.mobile_nav {
    display: none;
  }

  @media (max-width: 992px) {
    .mobile_nav {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
    .hamburger_container {
      flex: 1;
    }
    .nav_logo {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    
  }