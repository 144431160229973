.header_container {
  display: flex;
  background: #ffffff;
  width: 95%;
  margin: 1em auto 2em;
  border-radius: 5px;
  padding: 1em 0.5em;
}

.header_left {
  flex: 1;
  display: flex;
  align-items: center;
  padding: 1em;
}
.header_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.wallet_balance_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  text-align: center;
}

.grey_color {
  color: #495057;
}
