#sidebar_container {
  background: #000070;
  color: white;
  height: 100vh;
  width: 6vw;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 60px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90%;
  margin: auto;
}

#sidebar_container:hover {
  width: fit-content;
  z-index: 2;
}
.sidebar:hover {
  padding-left: 2em;
}
.sidebar:hover .middle_description,
.sidebar:hover .bottom_description {
  display: flex;
}

.sidebar_icon{
  height: 28px;
}

@media (max-height: 600px) {
  .sidebar {
    height: unset;
  }
}

@media (max-width: 992px) {
  #sidebar_container {
    display: none;
    width: auto;
    margin-top: 0;
  }

  #sidebar_container.sidebar__responsive {
    display: inline !important;
    z-index: 9999 !important;
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    height: 100vh;
    padding-bottom: 1em;
  }
  .sidebar {
    padding-left: 1em;
  }
  .sidebar:hover {
    padding-left: 1em;
  }
}
