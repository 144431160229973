.desktop_logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px){
    .desktop_logo{
        display: none;
    }
} 