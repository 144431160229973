.body {
  position: relative;
  max-width: 1900px;
  margin: auto;
  background: #efeff8;
  overflow-x: hidden;
  min-height: 100vh;
}

.main_section {
  margin-left: 6vw;
  margin-top: 100px;
}

.peer_to_peer_container {
  width: 95%;
  margin: 2em auto;
  background: #ffffff;
  border-radius: 10px;
  padding: 2em;
}

button{
  border: none;
  outline: none;
}

button:disabled{
  opacity: 0.5;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

.green_text {
  color: #1f7928;
}
.red_text {
  color: #af0707;
}
.grey_text {
  color: #565656;
}

.error_loading{
  color: #af0707;
  font-size: large;
}

@media (max-width: 992px) {
  .main_section {
    margin: 0;
    padding: 1em;
  }
}

@media (max-width: 768px) {
  .peer_to_peer_container {
    padding: 2em 0.5em;
  }
}
