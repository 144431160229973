.middle {
  width: 100%;
  flex: 7;
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}
.middle_options {
  flex: 1;
  max-height: 70px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 0.6em;
  color: white;
}
.middle_options:hover{
  background: #00b9cd;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 10px;
}
.middle_icon_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.middle_description {
  flex: 3;
  margin-left: 10px;
  display: none;
  justify-content: flex-start;
}

@media (max-width: 992px){
    .middle_description {
        display: flex;
      }
}

@media (max-height: 600px) {
  .middle_options{
    margin-top: 1.2em;
  }
}