.bottom {
  flex: 2;
  border-top: 1px solid #676b87;
  margin-top: 2em;
  padding: 2em 0 2em 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bottom_options {
  flex: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  cursor: pointer;
  margin-bottom: 1em;
}

.bottom_options:hover{
  background: #00b9cd;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 20px;
}

.bottom_icon_container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom_description {
  flex: 3;
  margin-left: 10px;
  display: none;
  justify-content: flex-start;
}

@media (max-width: 992px) {
  .bottom_description {
    display: flex;
  }
  .bottom {
    margin-top: 1.5em;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
  .bottom{
    margin-top: 0.5em;
  }
}
