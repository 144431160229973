* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  max-width: 1900px;
  margin: auto;
  font-family: "Quicksand", sans-serif;
  font-size: 14px;
  color: #000070;
}
