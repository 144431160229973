.not_found_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.not_found_container > h3{
    margin: 3em auto;
}

.return_home_button{
    padding: 1em 2em;
    background: #000070;
    color: #fff;
    border: none;
    outline: none;
    border-radius: 5px;
}