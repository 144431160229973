.success_ad_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 1em; 
}

.success_ad_container > h4, .success_ad_container > h3{
  margin: 0.5em auto;
}

#success_ad_link {
  background: #000070;
  color: white;
  text-decoration: none;
  border: 1px solid #000070;
  border-radius: 5px;
  padding: 5px 15px;
}
