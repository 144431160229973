.right {
  display: flex;
  align-items: center;
}

.log_out_icon_container{
  cursor: pointer;
}

.icon_column {
  display: flex;
  align-items: center;
}

.user_column {
  display: flex;
  align-items: center;
}

.user_details {
  text-align: right;
}

.user_icon {
  margin: 0 10px;
}

.dashboard_user_dp{
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

#drop-down {
  border: none;
  outline: none;
}

.icon_column {
  margin: 0 10px;
}

.icons {
  margin: 0 10px;
}

@media (max-width: 1440px) {
  .icons {
    width: 30px;
  }
  .icon_column {
    margin: 0;
  }
  .dashboard_user_dp{
    height: 30px;
  }
}

@media (max-width:1040px){
  .username_text{
    max-width: 100px;
    word-break: break-all;
  }
}

@media (max-width: 992px) {
  .right {
    display: none;
  }
}
