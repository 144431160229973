.center {
  display: flex;
  align-items: center;
}

.nav_btn {
  border-radius: 30px;
  margin: 0 0.5em;
  padding: 10px 20px;
  text-decoration: none;
}

.nav_btn-primary {
  background: #000070;
  color: #ffffff;
}

.nav_btn-light {
  border: 1px solid #000070;
}

.nav_btn-info {
  background: #00b9cd;
  color: #ffffff;
}

@media (max-width:1440px) {
  .nav_btn {
    padding: 8px 16px;
  }
}

@media (max-width: 992px) {
  .center {
    display: none;
  }
}