#nav{
    height: 70px;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 1900px;
    display: flex;
    background: white;
    z-index: 3;
}
.logo{
  background: #000070;
    width: 6vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em;
}

.nav_items{
    flex: 2;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (max-width:1600px){
    .logo{
        padding: 1.5em;
    }
}

@media (max-width:1440px){
    .logo{
        padding: 0.7em;
    }
}

@media (max-width:992px){
    #nav{
        position: unset;
    }
    .logo{
        display: none;
        width: 0;
    }
}