.back_header {
  padding-bottom: 1em;
  border-bottom: 1px solid #c9ced6;
  display: flex;
  align-items: center;
}

.back_header_left {
  margin-left: 2em;
  display: flex;
  align-items: center;
  flex: 1;
  background: #ffffff;
}

.back_header_left:disabled{
  pointer-events: none;
  opacity: 0;
}

.back_header_link{
  display: flex;
  align-items: center;
}

.back_header_icon_container {
  background: rgba(0, 0, 112, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 1em;
}

@media (max-width:576px){
    .back_header{
        flex-direction: column;
    }

    .back_header_left{
        margin-left: unset;
        margin-bottom: 2em;
    }
}