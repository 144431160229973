.upload_proof_back_header_right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.upload_proof_apply_to_sell_button {
  background: #ffffff;
  border: 1px solid #720101;
  border-radius: 5px;
  padding: 10px 20px;
  color: #720101;
}
/* Body */
.upload_proof_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 2em auto;
}

.upload_proof_container > h3, .upload_proof_container > p {
  margin: 0.5em auto;
  text-align: center;
}

.upload_proof_container > div {
  margin: 2em auto;
}

.seller_bank_details_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin: 2em auto;
}

.seller_bank_details_container {
  margin: 2em auto;
  width: 100%;
}

.seller_bank_details_row {
  display: flex;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0.5em;
  justify-content: space-around;
}

.seller_bank_details_row > div {
  flex: 1;
  display: flex;
  align-items: center;
}

.seller_bank_details_value {
  color: #565656;
}

.upload_proof_info_container {
  display: flex;
  width: 60%;
}

.upload_proof_info_container > img {
  align-self: flex-start;
  margin-right: 1em;
}

.upload_proof_input_container {
  border: 0.5px solid #969cba;
  display: flex;
  padding: 1em;
  cursor: pointer;
  position: relative; 
}

.upload_proof_input_container > img {
  margin-right: 1em;
}

.upload_proof_timer_container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.upload_proof_button_container {
  width: 40%;
  margin: 2em auto;
}

.upload_proof_button {
  width: 100%;
  padding: 1em;
  font-size: 12px;
  background: #000070;
  border-radius: 5px;
  border-color: #000070;
  color: #ffffff;
}

#inputFileToLoad {
  position: absolute;
  opacity: 0;
}

#imgTest > img {
  width: 250px;
  height: 250px;
}

@media (max-width: 992px) {
  .seller_bank_details_section {
    width: 90%;
  }

  .upload_proof_info_container {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .seller_bank_details_row {
    font-size: unset;
  }

  #imgTest > img {
    width: 150px;
    height: 150px;
  }
}
