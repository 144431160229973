.top {
  padding: 1em;
  display: none;
}
.sidebar_close_icon {
  display: none;
}

@media (max-width: 992px) {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .sidebar_close_icon {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    background: white;
  }
}
