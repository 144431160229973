a {
  cursor: pointer;
  text-decoration: none;
  color: unset;
}
a:visited,
a:active,
a:hover {
  text-decoration: none;
  color: none;
}

a:hover {
  color: none !important;
}

.pagination {
  margin: 2em auto;
  display: flex;
  justify-content: center;
  list-style: none;
  outline: none;
}
.pagination > .active > a {
  background-color: #000070;
  border-color: #000070;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #000070;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #000070;
  border-color: #000070;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #000070;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.login_container{
  background: url(./components/img/login\ background.png);
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
@media (max-width: 576px) {
  .login_container{
    background: #ffffff;
  }
}